@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #1B4720;
  --secondary-color: #A8D07D;
}ß

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  cursor: pointer;
}

/* .slick-prev:before,
.slick-next:before {
  color: var(--primary-color) !important;
  font-size: 40px !important;
} */

button {
  background: var(--primary-color);
  color: #FFFFFF;
  border: 1px solid var(--secondary-color);
  font-weight: bold;
  box-shadow: 0 0 6px rgba(168, 208, 125, 0.5);
}

button:hover {
  background: var(--secondary-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}


input[type="text"] {
  border-radius: 7px;
  padding: 7px;
  background: var(--primary-color);
  color: #FFFFFF;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 6px rgba(168, 208, 125, 0.5);
}

textarea {
  border-radius: 7px;
  padding: 7px;
  background: var(--primary-color);
  color: #FFFFFF;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 6px rgba(168, 208, 125, 0.5);
}

/* nav .nav-items a {
  margin-right: 20px;
  position: relative;
}

nav .nav-items a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  background-color: var(--primary-color);
  transition: transform 0.3s;
}

nav .nav-items a:hover:after {
  transform: scaleX(1);
} */

@keyframes fill {
  0% {
    transform: scale(1);
    background: transparent;
  }

  50% {
    transform: scale(0.8);
    background: var(--primary-color);
  }

  100% {
    background: transparent;
    transform: scale(1);
  }
}

.text-nowrap {
  text-wrap: nowrap;
}

.homepage .bg-circle {
  border-radius: 50%;
  position: absolute;
  opacity: 0.6;
}

.bg-dots>div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #1B4720;
  box-shadow: 0px 1px 2px rgba(168, 208, 125, 0.5), 0px 2px 4px rgba(168, 208, 125, 0.5),
    0px 4px 8px rgba(168, 208, 125, 0.5), 0px 8px 16px rgba(168, 208, 125, 0.5);
}

.banner-container {
  position: relative;
  background: linear-gradient(180deg, rgb(0 0 0) 0%, var(--primary-color) 100%);
}

.rev-banner-container {
  background: linear-gradient(180deg, var(--primary-color) 100%), rgb(0 0 0) 0%;
}

.laptop-image {
  animation: float 5s infinite;
  filter: brightness(0.6);
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-bottom: 15px solid black;
  border-radius: 5px;
}

@keyframes float {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(-8px);
  }
}

section#about-gallery {
  --childs: 5;
  --gap: 4px;
  width: 100%;
  display: flex;
  gap: var(--gap);
}

section#about-gallery .box {
  width: calc((100% / var(--childs)));
  filter: grayscale(1);
  box-sizing: border-box;
  height: min(30vmin, 250px);
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: width 500ms ease-in-out;
}

section#about-gallery .box:hover {
  filter: grayscale(0);
  width: calc((100% / (var(--childs) - 2)));
}

/* PRODUCT CARD */
.product {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 40px 30px;
  overflow: hidden;
}

.product-card-content {
  position: relative;
  z-index: 1;
}

.product-card-circle {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  width: 150px;
  height: 150px;
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  opacity: 0.5;
  transition: all 0.6s;
}

.product:hover .product-card-circle {
  width: 100%;
  height: 100%;
  transform: none;
  border: 0;
  border-radius: 0;
  opacity: 1;
}

/* GLASSMORPHISM */
/* .glassmorphism-white {
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
  backdrop-filter: blur(6px);
  border-radius: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: rgba(255, 255, 255, 0.5) -20px -20px 45px inset, rgba(0, 0, 0, 0.1) 10px 10px 20px, rgba(0, 0, 0, 0.06) 5px 5px 10px;
} */
.glassmorphism-container {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.glassmorphism-white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
  backdrop-filter: blur(3px);
  border-radius: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: rgba(255, 255, 255, 0.5) -20px -20px 45px inset, rgba(0, 0, 0, 0.1) 10px 10px 20px, rgba(0, 0, 0, 0.06) 5px 5px 10px;
}

.glassmorphism-black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
  backdrop-filter: blur(3px);
  border-radius: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.5) -20px -20px 45px inset, rgba(0, 0, 0, 0.1) 10px 10px 20px, rgba(0, 0, 0, 0.06) 5px 5px 10px;
}

.glassmorphism-primary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, rgba(27, 71, 32, 0.3) 0%, rgba(27, 71, 32, 0.1) 100%);
  backdrop-filter: blur(3px);
  border-radius: 15px;
  border-top: 1px solid rgba(27, 71, 32, 0.5);
  border-left: 1px solid rgba(27, 71, 32, 0.5);
  box-shadow: rgba(27, 71, 32, 0.5) -20px -20px 45px inset, rgba(27, 71, 32, 0.1) 10px 10px 20px, rgba(27, 71, 32, 0.06) 5px 5px 10px;
}

/* -------ANIMATIONS------- */
/* OUTER SPIN WITH ELEMENT  */
.spin-anim-300 {
  position: relative;
  width: 300px;
  height: 300px;
  display: block;
}

.spin-center-elem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

#layer-1 {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 5000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 5000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 5000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.shape-1,
.shape-4 {
  opacity: 0.2;
}

.shape-2,
.shape-5 {
  opacity: 0.5;
}

.shape-3,
.shape-6 {
  opacity: 0.5;
}

/* END OUTER SPIN WITH ELEMENT  */

/* HEADING SLIDER */
.square-primary {
  animation: headingSliderPrimary 2.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
  background: var(--primary-color);
}

.square-secondary {
  animation: headingSliderSecondary 2.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
  background: var(--secondary-color);
}

@keyframes headingSliderPrimary {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(0, 60px) rotate(360deg);
    background: var(--secondary-color);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes headingSliderSecondary {
  0% {
    transform: translate(0, -10px) rotate(0deg);
  }

  50% {
    transform: translate(0, 50px) rotate(360deg);
    background: var(--primary-color);
  }

  100% {
    transform: translate(0, 10px) rotate(0deg);
  }
}

/* END HEADING SLIDER */

/* BACKGROUND FLOATING PARTICLES */